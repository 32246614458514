import React from 'react'

import './style.scss';

const Spacing = () => {
  return (
    <div className='mobile-timeline' id="mobile-timeline-section"  >
    </div>
    
  )
}

export default Spacing